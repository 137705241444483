﻿import $ from "jquery";
import "./libs/native.history";
import _ from "underscore";

function getLocation() {
  if (navigator.geolocation) {
    try {
      navigator.geolocation.getCurrentPosition(showPosition);
    } catch (e) {
      console.log(e);
    }
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
}

function showPosition(position) {
  window.currentLocation = {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
  };
}

getLocation();

window.swLocations = function (opts) {
  opts = _.extend(
    {
      apiKey: "",
      apiUrl: "",
      controlId: "",
      debug: false,
      pagePartId: "",
      siteId: "",
      username: "",
      loaded: false,
    },
    opts
  );

  var l = _.extend(this, opts);
  l.imagePath = "/assets/" + l.siteId + "/";
  l.infowindow = null;

  // Change interpolation for Underscore.js templates from <%= ... %> to |%= ... %|
  // and evaluation from <% ... %> to |% ... %| so it plays nice w/ aspx pages
  _.templateSettings = {
    evaluate: /\|%(.+?)%\|/g,
    interpolate: /\|\%=(.+?)%\|/g,
  };

  if (l.debug === true) {
    if (window.console) console.log("Using jQuery " + $.fn.jquery);
  }
  /* --------------------- 
        ---------------------- */
  l.Data = {
    cleanNullValues: function (data) {
      for (var attr in data) {
        if (data[attr] == null || data[attr] == "null") {
          data[attr] = "";
        }
      }
      return data;
    },
  };
  l.Data.PlacesCollection = {
    baseUrl: l.apiUrl + "/locations/" + l.pagePartId,
    loaded: false,
    object: null,

    get: function (onSuccess, onError) {
      var c = this;
      $.ajax({
        url: this.baseUrl + ".json",
        data: { token: l.apiToken, siteId: l.siteId },
        success: function (data) {
          data.ShowMapTypeControl = data.ShowMapTypeControl == "true";
          data.ShowLocationFinder = data.ShowLocationFinder == "true";
          data.ShowLocationFinderName = data.ShowLocationFinderName == "true";
          data.DetermineZipCodeFromGeolocation =
            data.DetermineZipCodeFromGeolocation == "true";
          data.ShowLocationList = data.ShowLocationList == "true";
          data.ShowLocationImage = data.ShowLocationImage == "true";
          data.ShowCategoryList = data.ShowCategoryList == "true";
          data.ShowLargeMapControl = data.ShowLargeMapControl == "true";
          data.ListFeaturedLocationsFirst =
            data.ListFeaturedLocationsFirst == "true";
          data.HideMap = data.HideMap == "true";
          data.ShowFullAddressOnList = data.ShowFullAddressOnList == "true";
          data.CustomImageEnabled = data.CustomImageEnabled == "true";
          data.DataSource = data.DataSource ? data.DataSource : 0;
          c.object = data;
          c.loaded = true;

          if (onSuccess) onSuccess();
        },
        error: function (xhr, ajaxOptions, thrownError) {
          if (onError) onError();
        },
        complete: function (xhr, status) {},
      });
    },

    initialize: function (callback) {
      this.get(callback);
    },
  };

  l.Data.StoreDirectoryPlaces = {
    baseUrl: l.shopKitApiUrl + "/directories/",
    loaded: false,
    object: {},

    getAll: function (onSuccess, onError) {
      var c = this;
      $.ajax({
        url: this.baseUrl + l.Data.PlacesCollection.object.StoreDirectoryId,
        data: { api_key: l.apiKey, products: false, promotions: false },
        success: function (data) {
          c.object = data;
          c.loaded = true;
          if (onSuccess) onSuccess();
        },
        error: function (xhr, ajaxOptions, thrownError) {
          if (onError) onError();
        },
        complete: function (xhr, status) {},
      });
    },
    mapToPlace: function (store) {
      var v = this;
      store.PlaceId = store.Id;
      store.CenterPointLat = store.Latitude;
      store.CenterPointLong = store.Longitude;
      store.Zip = store.Zipcode;
      store.Categories = v.mapCategoriesToActivity(store.Categories);
      store.Activities = store.Categories;
      return store;
    },
    mapCategoriesToActivity: function (categories) {
      _.each(categories, function (c) {
        c.ActivityId = c.CategoryId;
      });
      return categories;
    },
    initialize: function (callback) {
      var v = this;
      this.getAll(function () {
        _.each(l.Data.StoreDirectoryPlaces.object.Stores, function (s) {
          s = v.mapToPlace(s);
        });
        l.Data.StoreDirectoryPlaces.object.Categories =
          v.mapCategoriesToActivity(
            l.Data.StoreDirectoryPlaces.object.Categories
          );
        if (callback) callback();
      });
    },

    reset: function () {
      this.objects = [];
      this.loaded = false;
    },
  };

  l.container = $("#placesmap_" + l.pagePartId);

  l.locationSearch = l.container.find(".places-app-search");
  l.loader = l.container.find(".places-app-loader");
  l.canvas = l.container.find(".places-app-map-canvas");
  l.searchZip = l.container.find(".places-app-search-zip");
  l.searchName = l.container.find(".places-app-search-name");
  l.searchNameWrapper = l.container.find(".places-app-search-name-wrapper");
  l.searchCountry = l.container.find("select[name*='ddlCountry']");
  l.searchButton = l.container.find(".places-app-search-button");
  l.searchDistance = l.container.find(".selMilesToSearch");
  l.searchClear = l.container.find(".places-app-search-clear");
  l.catList = l.container.find(".places-app-category-list");
  l.locList = l.container.find(".places-app-location-list");
  l.sortSelect = l.container.find(".js-places-sort");
  l.sortSelectLabel = l.container.find(".js-places-sort-label");
  l.searchActivities = [];
  l.searchLocs = [];
  l.defaultDistance = "25";
  l.stateCounter = 1;
  l.locSearch = "";
  l.locCountry = "";
  l.locDistance = "";
  l.locCatString = "";
  l.sortDir = l.sortSelect[0].options[0].value;

  l.History = window.History;
  l.History.useHash =
    window.location.search != null &&
    window.location.search.indexOf("locsearch") < 0 &&
    window.location.hash != null &&
    window.location.hash.indexOf("locsearch") > 0;
  //{{{ l.Utils
  l.Utils = {
    getParameterByName: function (name, useHash) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regexS = "[\\?#&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = "";
      if (useHash) {
        results = regex.exec(window.location.hash);
      } else {
        results = regex.exec(window.location.search);
      }
      if (results == null) return "";
      else return decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    pushPageView: function (url) {
      if (typeof _gaq !== "undefined" && _gaq !== null) {
        _gaq.push(["_trackPageview", url]);
      }
    },
    updateQueryStringParameter: function (uri, key, value) {
      var re = new RegExp("([?|&])" + key + "=.*?(&|$)", "i");
      separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
      } else {
        return uri + separator + key + "=" + value;
      }
    },
    clearQueryString: function () {
      window.location.search = "";
    },
  };
  l.Utils.Maps = {
    _geocoder: null,

    _map: null,

    _mapTypes: null,

    _matLatLng: null,

    _markers: new Array(),

    _degreesToRadians: function (degrees) {
      var pi = Math.PI;
      var radians = degrees * (pi / 180);
      return radians;
    },

    _radiansToDegrees: function (radians) {
      var pi = Math.PI;
      var degrees = radians * (180 / pi);
      return degrees;
    },
    removeSearchThisArea: function () {
      l.map.controls[google.maps.ControlPosition.TOP_CENTER].clear();
    },
    addSearchThisArea: function () {
      if (
        l.c.ShowMapSearch == "true" &&
        l.map.controls[google.maps.ControlPosition.TOP_CENTER].length === 0
      ) {
        var searchAreaDiv = document.createElement("div");
        var searchAreaControl = new l.Utils.Maps.SearchThisAreaControl(
          searchAreaDiv,
          l.map
        );
        searchAreaDiv.index = 1;
        l.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
          searchAreaDiv
        );
      }
    },
    SearchThisAreaControl: function (controlDiv, map) {
      // Set CSS for the control border.
      var controlUI = document.createElement("div");
      controlUI.style.backgroundColor = "#fff";
      controlUI.style.border = "2px solid #fff";
      controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlUI.style.cursor = "pointer";
      controlUI.style.marginBottom = "22px";
      controlUI.style.marginTop = "15px";
      controlUI.style.textAlign = "center";
      controlUI.title = "Search This Area";
      controlUI.style.zIndex = 2;
      controlDiv.appendChild(controlUI);

      // Set CSS for the control interior.
      var controlText = document.createElement("div");
      controlText.style.color = "#4285F4";
      controlText.style.textTransform = "uppercase";
      controlText.style.fontFamily = "Roboto,Arial,sans-serif";
      controlText.style.fontSize = "13px";
      controlText.style.lineHeight = "38px";
      controlText.style.paddingLeft = "5px";
      controlText.style.paddingRight = "5px";
      controlText.innerHTML = "Search This Area";
      controlUI.appendChild(controlText);

      // Setup the click event listeners: simply set the map to Chicago.
      controlUI.addEventListener("click", function () {
        l.searchFromMap = true;
        l.resetWholeLocationSearch();
        var queryString = l.getQueryForNameAndCategories("", "?");

        l.History.pushState(
          { state: l.stateCounter },
          window.location.title,
          window.location.protocol +
            "//" +
            window.location.hostname +
            window.location.pathname +
            queryString
        );
        l.buildResults(
          {
            latitude: l.map.center.lat(),
            longitude: l.map.center.lng(),
          },
          true
        );
      });
    },
    geocode: function (address, region, onSuccess, onError) {
      this._geocoder.geocode(
        { address: address, region: region },
        function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            onSuccess({
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
            });
          } else {
            onError();
          }
        }
      );
    },
    map: function (canvas, $collection) {
      l.c = $collection;
      // set the map type
      switch (l.c.MapViewType) {
        case "G_SATELLITE_MAP":
          l.mapTypeId = google.maps.MapTypeId.SATELLITE;
          break;
        case "G_HYBRID_MAP":
          l.mapTypeId = google.maps.MapTypeId.HYBRID;
          break;
        default:
          l.mapTypeId = google.maps.MapTypeId.ROADMAP;
          break;
      }
      //Load map
      l.mapOptions = {
        center: new google.maps.LatLng(l.c.CenterPointLat, l.c.CenterPointLong),
        zoom: l.c.ZoomLevel,
        mapTypeId: l.mapTypeId,
        mapTypeControl: l.c.ShowMapTypeControl,
        panControl: l.c.ShowLargeMapControl,
        zoomControl: l.c.ShowLargeMapControl,
        streetViewControl: l.c.ShowLargeMapControl,
        mapTypeControlOptions: {
          mapTypeIds: ["custom"],
        },
      };
      if (
        window.currentLocation === undefined ||
        window.currentLocation === null
      ) {
        window.currentLocation = {
          latitude: l.mapOptions.center.lat(),
          longitude: l.mapOptions.center.lng(),
        };
      }
      l.map = new google.maps.Map(
        document.getElementById("places-map-" + l.pagePartId),
        l.mapOptions
      );

      l.map.addListener("dragend", l.Utils.Maps.addSearchThisArea);
      l.map.addListener("zoom_changed", l.Utils.Maps.addSearchThisArea);

      if (l.c.CustomImageEnabled) {
        var customTypeOptions = {
          getTileUrl: function (coord, zoom) {
            var normalizedCoord = l.Utils.Maps.getNormalizedCoord(coord, zoom);
            if (!normalizedCoord) {
              return null;
            }
            var bound = Math.pow(2, zoom);
            return (
              "/sitefiles/" +
              l.siteId +
              "/locator-map-" +
              l.pagePartId +
              "/" +
              zoom +
              "/" +
              normalizedCoord.x +
              "/" +
              (bound - normalizedCoord.y - 1) +
              ".png"
            );
          },
          tileSize: new google.maps.Size(256, 256),
          maxZoom: 20,
          minZoom: 0,
          radius: 1738000,
          name: "", // this sets a label in the upper right of the map
        };

        var customMapType = new google.maps.ImageMapType(customTypeOptions);

        l.map.mapTypes.set("custom", customMapType);
        l.map.setMapTypeId("custom");
      }

      l.canvas
        .css("height", l.c.MapHeight + "px")
        .css("width", l.c.MapWidth + "px");
    },
    getNormalizedCoord: function (coord, zoom) {
      var y = coord.y;
      var x = coord.x;

      // tile range in one direction range is dependent on zoom level
      // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
      var tileRange = 1 << zoom;

      // don't repeat across y-axis (vertically)
      if (y < 0 || y >= tileRange) {
        return null;
      }

      // repeat across x-axis
      if (x < 0 || x >= tileRange) {
        x = ((x % tileRange) + tileRange) % tileRange;
      }

      return {
        x: x,
        y: y,
      };
    },

    build_marker: function ($place, $i, $activity) {
      var p_center = new google.maps.LatLng(
        $place.CenterPointLat,
        $place.CenterPointLong
      );
      var p_image = new google.maps.MarkerImage(null);
      var p_shadow = new google.maps.MarkerImage(null);
      var p_marker = null;
      var p_activity;
      var p_default_icon = true;
      if ($activity != null) {
        p_activity = $activity;
      } else if ($place.Activities.length > 0) {
        p_activity = $place.Activities[0];
      }
      var p_shape = {
        coord: [0, 0, 32, 0, 32, 37, 0, 37],
        type: "poly",
      };

      if (p_activity != null) {
        if (
          p_activity.ParentCategoryId != null &&
          p_activity.ParentCategoryId > 0
        ) {
          var parent_act = _.find(
            l.Data.PlacesCollection.object.Activities,
            function (item) {
              return item.ActivityId == p_activity.ParentCategoryId;
            }
          );
          if (
            parent_act &&
            parent_act.MarkerIconURL != null &&
            parent_act.MarkerIconURL != ""
          ) {
            p_activity.MarkerIconURL = parent_act.MarkerIconURL;
          }
          if (
            parent_act &&
            parent_act.MarkerIconShadowURL != null &&
            parent_act.MarkerIconShadowURL != ""
          ) {
            p_activity.MarkerIconShadowURL = parent_act.MarkerIconShadowURL;
          }
        }
        if (
          p_activity.MarkerIconURL != null &&
          p_activity.MarkerIconURL != ""
        ) {
          p_default_icon = false;
          p_image = new google.maps.MarkerImage(
            l.imagePath + p_activity.MarkerIconURL,
            new google.maps.Size(37, 32),
            new google.maps.Point(0, 0),
            new google.maps.Point(0, 32)
          );
          p_marker = new google.maps.Marker({
            position: p_center,
            map: l.map,
            shape: p_shape,
            title: $place.Name,
            icon: p_image,
          });
          if (
            p_activity.MarkerIconShadowURL != null &&
            p_activity.MarkerIconShadowURL != ""
          ) {
            p_shadow = new google.maps.MarkerImage(
              l.imagePath + p_activity.MarkerIconShadowURL,
              new google.maps.Size(37, 34),
              new google.maps.Point(0, 0),
              new google.maps.Point(-15, 32)
            );
            p_marker = new google.maps.Marker({
              position: p_center,
              map: l.map,
              shape: p_shape,
              title: $place.Name,
              icon: p_image,
              shadow: p_shadow,
            });
          }
        }
      }
      if (p_default_icon == true) {
        p_marker = new google.maps.Marker({
          position: p_center,
          map: l.map,
          shape: p_shape,
          title: $place.Name,
        });
      }
      var p_addy = _.template($("#place-address").html());
      p_addy = p_addy({
        data: $place,
        showAddress: l.p.ShowFullAddressOnList,
        showLocationImage: l.p.ShowLocationImage,
        cleanPhone: cleanPhone,
        l: l,
      });
      var p_info = new google.maps.InfoWindow({
        content: p_addy,
        zIndex: 1,
      });
      p_info.setOptions({
        maxWidth: 250,
      });
      this._markers[$i] = p_marker;
      google.maps.event.addListener(p_marker, "click", function () {
        if (l && l.map) {
          if (l.infowindow) {
            l.infowindow.close();
          }
          l.Utils.Maps.removeSearchThisArea();
          l.map.panTo(p_marker.getPosition());
          l.infowindow = p_info;
          p_info.open(l.map, p_marker);
        }
      });
    },
    clearOverlays: function () {
      if (this._markers) {
        for (var i = 0; i < this._markers.length; i++) {
          this._markers[i].setMap(null);
        }
      }
    },

    distanceBetweenLatLon: function (lat1, lon1, lat2, lon2) {
      lat1 = this._degreesToRadians(lat1);
      lon1 = this._degreesToRadians(lon1);
      lat2 = this._degreesToRadians(lat2);
      lon2 = this._degreesToRadians(lon2);

      //http://www.meridianworlddata.com/distance-calculation.asp
      var dist =
        3963.0 *
        Math.acos(
          Math.sin(lat1) * Math.sin(lat2) +
            Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
        );
      return dist;
    },

    getZoomLevel: function (distance) {
      if (distance == 0) return 10;
      if (distance < 0.2) return 16;
      if (distance < 0.5) return 15;
      if (distance < 1) return 14;
      if (distance < 2) return 13;
      if (distance < 3) return 12;
      if (distance < 7) return 11;
      if (distance < 15) return 10;
      if (distance < 30) return 9;
      if (distance < 60) return 8;
      if (distance < 120) return 7;
      if (distance < 240) return 6;
      if (distance < 480) return 5;
      if (distance < 960) return 4;
      if (distance < 1920) return 3;
      if (distance < 3840) return 2;
      //too big, return largest
      return 1;
    },

    initialize: function () {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "//maps.googleapis.com/maps/api/js?key=" +
        l.Data.PlacesCollection.object.GoogleMapsAPIKey +
        "&sensor=false&callback=l_" +
        l.pagePartId +
        ".Utils.Maps.loaded";
      document.body.appendChild(script);
    },

    loaded: function () {
      l.Utils.Maps._geocoder = new google.maps.Geocoder();
    },
  };
  //}}}

  l.resetLocationFields = function () {
    l.searchDistance.val(l.defaultDistance);
    l.locList.html("");
    l.searchZip.val("");
    l.searchCountry.val("US");
    l.sortDir = l.sortSelect[0].options[0].value;
  };

  l.resetLoc = function () {
    l.locSearch = "";
    l.locCountry = "";
    l.locDistance = "";
    l.locCatString = "";
  };

  l.resetState = function () {
    l.History.pushState(
      { state: l.stateCounter },
      window.location.title,
      window.location.protocol +
        "//" +
        window.location.hostname +
        window.location.pathname
    );
    l.stateCounter++;
  };

  l.resetWholeLocationSearch = function () {
    l.resetLocationFields();
    l.resetLoc();
  };

  l.resetAll = function () {
    if (!l.p.HideMap) {
      l.resetMap();
    }
    if (l.p.ShowCategoryList) {
      l.resetActivities();
    }
    l.searchName.val("");
    l.locSearchName = "";
    l.resetWholeLocationSearch();
    l.resetState();
  };

  l.resetMap = function () {
    l.Utils.Maps.clearOverlays();
    l.Utils.Maps.map(l.canvas, l.p);
  };

  l.resetActivities = function () {
    var aCount = 0;
    $.each(l.p.Activities, function () {
      l.searchActivities[aCount] = this.ActivityId;
      aCount++;
    });
    if (l.catCheck != undefined) {
      l.catCheck.attr("checked", true);
    }
  };

  l.initializeData = function (cb) {
    l.Data.PlacesCollection.initialize(function () {
      l.p = l.Data.PlacesCollection.object;
      if (l.p.DataSource == 1 && l.p.StoreDirectoryId > 0) {
        l.Data.StoreDirectoryPlaces.initialize(function () {
          l.p.Places = l.Data.StoreDirectoryPlaces.object.Stores;
          l.p.Activities = l.Data.StoreDirectoryPlaces.object.Categories;
          if (cb) cb();
        });
      } else {
        if (cb) cb();
      }
    });
  };

  l.init = function () {
    l.initializeData(function () {
      if (l.p.ShowCategoryList) {
        l.resetActivities();
      }

      if (l.p.ShowLocationFinderName) {
        l.searchNameWrapper.show();
      } else {
        l.searchNameWrapper.hide();
      }

      //Establish initial Activity Array to use in search
      l.Utils.Maps.initialize();
      l.loadMap = function () {
        //Must continually load function until google maps API is established
        if (l.Utils.Maps._geocoder == null) {
          setTimeout(l.loadMap, 150);
          return;
        }

        l.defaultPosition = {
          longitude: l.p.CenterPointLong,
          latitude: l.p.CenterPointLat,
        };

        if (!l.p.HideMap) {
          l.Utils.Maps.map(l.canvas, l.p);
        } else {
          l.canvas.hide();
        }

        if (l.p.ShowCategoryList) {
          var catTemp = _.template($("#category-item").html());
          for (var i = 0; i < l.p.Activities.length; i++) {
            var catString = catTemp({
              data: l.p.Activities[i],
              initChecked:
                l.Data.PlacesCollection.object.CategoryFilterMethod == "OR",
            });
            l.catList.append(catString);
          }
          l.catItem = l.catList.find(".places-app-category-list li");
          l.catCheck = l.container.find(
            ".places-app-category-list li input[type='checkbox']"
          );
          l.catCheck.click(function (e) {
            var aCount = 0;
            l.searchActivities = [];
            var c = $(e.target);
            if (c.hasClass("places-app-sub-category") && c.is(":checked")) {
              l.checkCatParents(c);
            }
            if (!c.hasClass("places-app-primary-category")) {
              l.checkCatSubs(c);
            }
            l.catCheck.each(function () {
              var t = $(this);
              if (
                t.hasClass("places-app-primary-category") &&
                t.is(":checked")
              ) {
                l.searchActivities[aCount] = parseInt(
                  $(this).attr("id").substring(4)
                );
                aCount++;
              }
            });
            if (!l.p.ShowLocationFinder) {
              //  l.buildResults(l.defaultPosition);
              l.searchPlaces(false);
            }
          });

          l.checkCatParents = function (t) {
            var p_cat = t
              .closest(".places-app-parent-categories")
              .find(".places-app-parent-category");
            if (t.is(":checked")) {
              if (!p_cat.is(":checked")) {
                p_cat.attr("checked", true);
              }
            } else {
              if (
                t
                  .closest(".places-app-parent-categories")
                  .find(".places-app-sub-category:checked").length <= 0
              ) {
                p_cat.attr("checked", false);
              }
            }
          };
          l.checkCatSubs = function (c) {
            var cats = c
              .parent("li")
              .find(".places-app-sub-categories li input");
            _.each(cats, function (cat) {
              cat = $(cat);
              cat.attr("checked", c.is(":checked"));
            });
          };
          l.catList.find(".places-app-sub-category").click(function (e) {
            var t = $(e.target);
            l.checkCatParents(t);
          });
        } else {
          l.catList.hide();
        }

        l.loader.hide();

        l.loaded = true;

        l.searchButton.click(function () {
          l.searchPlaces();
          return false;
        });

        l.searchZip.keydown(function (e) {
          if (e.keyCode == 13) {
            l.searchPlaces();
            e.preventDefault();
            //return false;
          }
        });
        l.searchCountry.keydown(function (e) {
          if (e.keyCode == 13) {
            l.searchPlaces();
            e.preventDefault();
            //return false;
          }
        });

        l.searchClear.click(function () {
          l.resetAll();
          return false;
        });

        if (l.p.ShowLocationList && l.sortSelect && l.sortSelect.length) {
          l.sortSelect.change(function () {
            if (l.searchZip.val() != "") {
              l.searchPlaces();
            }
          });
        } else {
          l.sortSelect.hide();
          l.sortSelectLabel.hide();
        }

        l.matchName = function (locName) {
          if (l.searchName.val() == "") return true;
          if (
            locName.toLowerCase().indexOf(l.searchName.val().toLowerCase()) > -1
          )
            return true;
          return false;
        };

        l.longestDistance = 0;
        l.distanceToSearch = 0;
        l.placeCounter = 0;
        l.searchFromMap = false;

        l.clearListOfPlaces = function () {
          l.locList.hide();
          l.locList.html("");
        };

        l.placeIsInBounds = function (place, bounds) {
          if (l.searchFromMap) {
            return l.map
              .getBounds()
              .contains(
                new google.maps.LatLng(
                  place.CenterPointLat,
                  place.CenterPointLong
                )
              );
          } else {
            var distanceInMiles = l.Utils.Maps.distanceBetweenLatLon(
              bounds.latitude,
              bounds.longitude,
              place.CenterPointLat,
              place.CenterPointLong
            );
            return (
              distanceInMiles < l.distanceToSearch || l.distanceToSearch == 0
            );
          }
        };

        l.checkAndAddPlace = function (i, results, catFilterType) {
          l.pl = l.p.Places[i];

          if (l.placeIsInBounds(l.pl, results)) {
            var distanceInMiles = l.Utils.Maps.distanceBetweenLatLon(
              results.latitude,
              results.longitude,
              l.pl.CenterPointLat,
              l.pl.CenterPointLong
            );

            if (distanceInMiles > l.longestDistance) {
              l.longestDistance = distanceInMiles;
            }
            var lmarkerAdded = false;
            var filterCategories =
              l.p.ShowCategoryList ||
              l.Utils.getParameterByName("loccategories", l.History.useHash) !=
                "";
            if (
              filterCategories &&
              l.pl.Activities &&
              l.pl.Activities.length > 0
            ) {
              if (catFilterType == "OR") {
                $.each(l.pl.Activities, function () {
                  var pact = this;
                  if (
                    !lmarkerAdded &&
                    (l.searchActivities == null ||
                      l.searchActivities.length < 1 ||
                      $.inArray(pact.ActivityId, l.searchActivities) > -1)
                  ) {
                    if (l.matchName(l.pl.Name)) {
                      l.searchLocs[l.placeCounter] = l.pl;
                      l.searchLocs[l.placeCounter].Distance = distanceInMiles;
                      l.pl.markerId = l.placeCounter;
                      if (l.Utils && l.Utils.Maps && l.Utils.Maps.LatLng) {
                        l.Utils.Maps.LatLng(l.pl, l.placeCounter, pact);
                      }
                      l.Utils.Maps.build_marker(l.pl, l.placeCounter, null);
                      lmarkerAdded = true;
                      l.placeCounter++;
                    }
                  }
                });
              } else {
                var foundActivities = [];
                var activityIds = [];
                $.each(l.pl.Activities, function () {
                  activityIds.push(this.ActivityId);
                });
                for (var c = 0; c < l.searchActivities.length; c++) {
                  foundActivities[c] = _.contains(
                    activityIds,
                    parseInt(l.searchActivities[c])
                  );
                }

                // matches all
                if (!lmarkerAdded && $.inArray(false, foundActivities) == -1) {
                  if (l.matchName(l.pl.Name)) {
                    l.searchLocs[l.placeCounter] = l.pl;
                    l.searchLocs[l.placeCounter].Distance = distanceInMiles;
                    l.pl.markerId = l.placeCounter;
                    if (l.Utils && l.Utils.Maps && l.Utils.Maps.LatLng) {
                      l.Utils.Maps.LatLng(l.pl, l.placeCounter, pact);
                    }
                    l.Utils.Maps.build_marker(l.pl, l.placeCounter, null);
                    lmarkerAdded = true;
                    l.placeCounter++;
                  }
                }
              }
            } else if (l.searchActivities.length <= 0) {
              if (l.matchName(l.pl.Name)) {
                l.searchLocs[l.placeCounter] = l.pl;
                l.pl.markerId = l.placeCounter;
                l.searchLocs[l.placeCounter].Distance = distanceInMiles;
                l.Utils.Maps.build_marker(l.pl, l.placeCounter, null);
                l.placeCounter++;
              }
            }
          }
        };

        l.buildLocationList = function () {
          l.locList.html("");
          var orderList = [];
          var locaList = [];
          locaList.length = 0;
          locaList = l.searchLocs;
          orderList.length = 0;
          for (var i = 0, len = locaList.length; i < len; i++) {
            if (locaList[i] != null) {
              locaList[i].Used = false;
            }
          }

          var sortFn = getSortFunction(l.sortDir);

          if (l.p.ListFeaturedLocationsFirst == true) {
            var featuredList = [];
            var notFeatured = [];
            var orderListFeatured = [];
            var orderListNotFeatured = [];

            for (var i = 0, len = locaList.length; i < len; i++) {
              var loc = locaList[i];
              if (loc.IsFeatured) {
                featuredList.push(loc);
              } else {
                notFeatured.push(loc);
              }
            }

            orderListFeatured = sortFn(featuredList);
            orderListNotFeatured = sortFn(notFeatured);
            orderList = orderListFeatured.concat(orderListNotFeatured);
          } else {
            orderList = sortFn(locaList);
          }

          var locTemp = _.template($("#place-address-string").html());
          for (var i = 0, len = locaList.length; i < len; i++) {
            var locString = locTemp({
              data: orderList[i],
              showAddress: l.p.ShowFullAddressOnList,
              showLocationImage: l.p.ShowLocationImage,
              cleanPhone: cleanPhone,
            });
            l.locList.append(locString);
          }
          l.locList
            .find("li.places-app-location-item span")
            .click(function (e) {
              var current = $(this);
              var markerId = current.parent("li").data("marker-id");
              google.maps.event.trigger(
                l.Utils.Maps._markers[markerId],
                "click"
              );
              if (e.target.tagName != "A") {
                e.preventDefault();
              }
            });
        };

        l.positionAndZoomMapToLongestDistance = function (results) {
          //Re-position map
          l.map.panTo(
            new google.maps.LatLng(results.latitude, results.longitude)
          );
          if (l.p.ShowLocationFinder) {
            l.map.setZoom(l.Utils.Maps.getZoomLevel(l.longestDistance));
          }
        };

        l.buildResults = function (results) {
          //Remove all current overlays
          l.Utils.Maps.clearOverlays();
          l.searchLocs = [];
          //Create new points from search
          if (l.searchFromMap) {
            var mapCoords = l.map.getBounds();
            var ne = mapCoords.getNorthEast();
            var sw = mapCoords.getSouthWest();
            l.distanceToSearch =
              l.Utils.Maps.distanceBetweenLatLon(
                ne.lat(),
                ne.lng(),
                sw.lat(),
                sw.lng()
              ) / 2;
          } else {
            l.distanceToSearch = l.searchDistance.val();
            if (!l.p.ShowLocationFinder) {
              l.distanceToSearch = 0;
            }
          }

          l.longestDistance = 0;
          l.clearListOfPlaces();

          var catFilterType =
            l.Data.PlacesCollection.object.CategoryFilterMethod;
          l.placeCounter = 0;
          for (var i = 0; i < l.p.Places.length; i++) {
            l.checkAndAddPlace(i, results, catFilterType);
          }

          if (
            !l.searchFromMap &&
            l.searchCountry.val() != "US" &&
            l.searchCountry.val() != "CA"
          ) {
            l.longestDistance = 950; // perfect for Australia, a little far out for Japan/UK
            //longestDistance = 450; // too close, maybe even for Japan/UK
          }

          if (l.p.ShowLocationList) {
            l.buildLocationList();
          }
          if (!l.p.HideMap && !l.searchFromMap) {
            l.positionAndZoomMapToLongestDistance(results);
          }
          if (l.p.ShowLocationList) {
            l.locList.show();
          }
        };

        function getSortDirection() {
          var sortDir = l.sortSelect.val();
          if (sortDir == null || sortDir == "") {
            sortDir = l.sortSelect[0].options[0].value;
          }

          return sortDir;
        }

        l.getQueryForNameAndCategories = function (stateString, querySymbol) {
          var qSymbol = querySymbol;
          if (l.p.ShowLocationFinder && l.searchName.val() != "") {
            stateString += qSymbol + "locationName=" + l.searchName.val();
            qSymbol = "&";
          }
          if (l.p.ShowCategoryList) {
            l.searchActivities = [];
            l.catCheck.each(function () {
              var t = $(this);
              if (
                t.hasClass("places-app-primary-category") &&
                t.is(":checked")
              ) {
                if (t.hasClass("places-app-sub-category")) {
                  l.checkCatParents(t);
                }
                l.searchActivities.push(parseInt($(this).val()));
              }
            });
            var categoryString = l.searchActivities.join(",");
            if (categoryString == "") {
              categoryString = "none";
            }
            stateString += qSymbol + "loccategories=" + categoryString;
          }
          return stateString;
        };

        l.searchPlaces = function (dont_push_state) {
          l.searchFromMap = false;
          l.searching = true;
          if (l.p.ShowLocationFinder) {
            if (
              l.searchZip.val() != "" ||
              (l.searchCountry.val() != "US" && l.searchCountry.val() != "CA")
            ) {
              var searchParam = l.searchZip.val();
              var distanceString = l.searchDistance.val();
            } else {
              if (
                l.searchCountry.val() != "US" ||
                l.searchCountry.val() != "CA"
              ) {
                alert(
                  "We couldn't find that location. Please enter a valid ZIP or City/State."
                );
                return;
              } else {
                alert("We couldn't find that location.");
                return;
              }
            }
          }

          if (!dont_push_state) {
            var stateString = window.location.pathname;
            var querySymbol = "?";

            if (l.p.ShowLocationFinder) {
              if (distanceString == "") {
                distanceString = "any";
              }
              stateString =
                stateString +
                querySymbol +
                "locsearch=" +
                l.searchZip.val() +
                "&loccountry=" +
                l.searchCountry.val() +
                "&locdistance=" +
                distanceString;
              querySymbol = "&";

              if (l.p.ShowLocationList) {
                stateString += "&sortdir=" + getSortDirection();
              }
            }
            stateString = l.getQueryForNameAndCategories(
              stateString,
              querySymbol
            );
            l.History.pushState(
              { state: l.stateCounter },
              window.location.title,
              stateString
            );
            l.stateCounter++;
            l.Utils.pushPageView(window.location);
          }
          if (l.searchZip.val() == "") {
            // build result by current location, if no location is entered
            l.buildResults(window.currentLocation);
            return;
          } else {
            l.Utils.Maps.geocode(
              searchParam,
              l.searchCountry.val(),
              function (results) {
                l.buildResults(results);
              },
              function () {
                if (l.p.ShowLocationFinder && l.searching) {
                  if (
                    l.searchCountry.val() != "US" ||
                    l.searchCountry.val() != "CA"
                  ) {
                    alert(
                      "We couldn't find that location. Please enter a valid ZIP or City/State."
                    );
                  } else {
                    alert("We couldn't find that location.");
                  }
                }
              }
            );
          }
        };
        //Bind action to run when new search takes place (save state)
        l.History.Adapter.bind(window, "statechange", function () {
          // Note: We are using statechange instead of popstate`
          var State = l.History.getState();
          l.setSearchFromQuery();
          //Check to make sure we are not clearing search
          if (
            !l.searchFromMap &&
            l.p.ShowLocationFinder &&
            l.locCatString != ""
          ) {
            l.searchPlaces(true);
          } else if (l.locCatString != "") {
            l.buildResults(l.defaultPosition);
          } else {
            if (!l.p.HideMap && !l.searchFromMap) {
              l.resetMap();
            }
            if (
              l.p.ShowCategoryList ||
              l.Utils.getParameterByName("loccategories", l.History.useHash) !=
                ""
            ) {
              l.resetActivities();
            }
            l.locList.html("");
          }
        });

        //Get query from query string
        l.setSearchFromQuery = function () {
          //Check to use hash again since the method might have changed after starting with hash/query
          l.History.useHash =
            (window.location.hash != null) & (window.location.hash != "") &&
            (window.location.hash.indexOf("locsearch") > 0 ||
              window.location.hash.indexOf("locategories") > 0);
          //Get search terms from hash
          if (l.p.ShowLocationFinder) {
            l.locSearch = l.Utils.getParameterByName(
              "locsearch",
              l.History.useHash
            );
            l.locSearchName = l.Utils.getParameterByName(
              "locationName",
              l.History.useHash
            );
            l.locCountry = l.Utils.getParameterByName(
              "loccountry",
              l.History.useHash
            );
            l.locDistance = l.Utils.getParameterByName(
              "locdistance",
              l.History.useHash
            );
            l.sortDir = l.Utils.getParameterByName(
              "sortdir",
              l.History.useHash
            );
          }

          l.locCatString = l.Utils.getParameterByName(
            "loccategories",
            l.History.useHash
          );

          if (l.locCatString != "") {
            l.searchActivities.length = 0;
            var url_cats = l.locCatString.split(",");

            if (l.catCheck) {
              l.catCheck.each(function () {
                var t = $(this);
                var catId = t.val();
                if (
                  t.hasClass("places-app-primary-category") &&
                  url_cats.indexOf(catId) > -1
                ) {
                  if (t.hasClass("places-app-sub-category")) {
                    l.checkCatParents(t);
                  }
                  t.attr("checked", true);
                  l.searchActivities.push(parseInt(t.val()));
                } else {
                  t.attr("checked", false);
                }
              });
            } else if (url_cats.length > 0) {
              _.each(url_cats, function (u) {
                l.searchActivities.push(parseInt(u));
              });
            }
          } else {
            // l.searchActivities.length = 0;
            // l.searchActivities = _.pluck(l.p.Activities, "ActivityId");
          }

          if (l.p.ShowLocationFinder) {
            l.searchZip.val(l.locSearch);
            l.searchName.val(l.locSearchName);

            if (l.locCountry != "") l.searchCountry.val(l.locCountry);
            if (l.locDistance != "") l.searchDistance.val(l.locDistance);
            if (l.sortDir != "") l.sortSelect.val(l.sortDir);
            if (l.locDistance == "any") l.searchDistance.val("");
          }
        };

        //Don't automatically load in locaitons
        //Show location finder
        l.locSearch = l.Utils.getParameterByName(
          "locsearch",
          l.History.useHash
        );
        l.locCatString = l.Utils.getParameterByName(
          "loccategories",
          l.History.useHash
        );
        l.locSearchName = l.Utils.getParameterByName(
          "locationName",
          l.History.useHash
        );
        //if the query string/hash term is present, run search
        if (
          l.locSearch != "" ||
          l.locCatString != "" ||
          l.locSearchName != ""
        ) {
          //get from query string
          l.setSearchFromQuery();
          //run search
          l.searchPlaces(true);
          //check if hash is being used for search
          l.History.useHash =
            (window.location.hash != null) & (window.location.hash != "") &&
            window.location.hash.indexOf("locsearch") > 0;
          if (l.p.ShowLocationFinder) {
            l.locationSearch.show();
          } else {
            l.buildResults(l.defaultPosition);
          }
        } else if (l.p.ShowLocationFinder) {
          if (l.p.DetermineZipCodeFromGeolocation) {
            getCurrentZip(function (zip) {
              l.searchZip.val(zip);
              l.searchPlaces();
            });
          }
          l.locationSearch.show();
        } else {
          //location finder automatically hidden
          //load in locations
          if (l.ShowCategoryList) {
            l.resetActivities();
          }
          l.buildResults(l.defaultPosition);
        }
      }; // end loadMap()

      l.loadMap();
    });
  };
  return this;
};

function getSortFunction(sortDir) {
  var sortFn = sortByDistanceAsc;
  switch (sortDir) {
    case "name-asc":
      sortFn = sortByNameAsc;
      break;
    case "distance-asc":
    default:
      sortFn = sortByDistanceAsc;
      break;
  }

  return sortFn;
}

function sortByDistanceAsc(unorderedList) {
  var orderedList = [];
  for (var i = 0, len = unorderedList.length; i < len; i++) {
    var biggestItem = 0;
    var biggestDistance = -1;
    //Loop through locations foreach location
    for (var j = 0; j < unorderedList.length; j++) {
      //If the distance of this location is greater than the recorded biggest distance, and has not been used, make it biggest distance
      if (
        unorderedList[j].Distance > biggestDistance &&
        !unorderedList[j].Used
      ) {
        biggestItem = j;
        biggestDistance = unorderedList[j].Distance;
      }
    }
    //add the biggestItem to the orderList array
    orderedList.push(unorderedList[biggestItem]);
    //Mark item as used
    unorderedList[biggestItem].Used = true;
  }
  orderedList.reverse();
  return orderedList;
}

function sortByNameAsc(unorderedList) {
  var orderedList = unorderedList.slice(0);
  return orderedList.sort(function (a, b) {
    if (a.Name > b.Name) {
      return 1;
    }
    if (a.Name < b.Name) {
      return -1;
    }
    return 0;
  });
}

function getCurrentZip(success, fail) {
  fail = fail || function () {};
  success = success || function () {};

  try {
    window.navigator.geolocation.getCurrentPosition(
      function getCurrentPositionSuccess(pos) {
        $.getJSON(
          "//maps.googleapis.com/maps/api/geocode/json?latlng=" +
            pos.coords.latitude +
            "," +
            pos.coords.longitude +
            "&sensor=true"
        )
          .done(function (res) {
            var currentZip = res.results[0].address_components.filter(function (
              c
            ) {
              return c.types.some(function (t) {
                return t == "postal_code";
              });
            })[0].short_name;

            if (currentZip == null || currentZip == "") {
              fail();
            } else {
              success(currentZip);
            }
          })
          .fail(function () {
            fail();
          });
      },
      function getCurrentPositionFail() {
        fail();
      }
    );
  } catch (o_O) {
    fail();
  }
}

function cleanPhone(phone) {
  if (phone == null) phone = "";

  return phone.replace(/[^0-9]/gi, "");
}
